<template>
  <v-container fluid class="pa-0">
    <back-to-store :title="$t('order.purchaseHistory')"></back-to-store>
    <v-container class="pa-16" fluid>
      <v-row class="flex-column-reverse flex-lg-row">
        <v-col cols="12" lg="8" class="d-none d-lg-flex">
          <v-simple-table>
            <template>
              <thead class="josefin-light font-weight-regular ">
                <tr>
                  <th class="text-left p pl-0">
                    {{ $t("order.orderNo") }}
                  </th>
                  <th class="text-leftr p">
                    {{ $t("order.createdAt") }}
                  </th>
                  <th class="text-left p">
                    {{ $t("order.shippingAddress") }}
                  </th>
                  <th class="text-left p">
                    {{ $t("order.total") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in getOrders.data" :key="index">
                  <td class="font-weight-regular text-left josefin-light gold">
                    <router-link class="gold" :to="'orders/' + item.id">
                      {{ item.id }}
                    </router-link>
                  </td>
                  <td class="font-weight-regular text-left josefin-light">
                    {{ moment(item.created_at).format('YYYY-MM-DD') }}
                  </td>
                  <td class="font-weight-regular text-left josefin-light pr-10">
                    {{
                      item.shipping_address.address1[0] +
                        ", " +
                        item.shipping_address.city +
                        ", " +
                        item.shipping_address.country_name
                    }}
                  </td>
                  <td class="font-weight-regular text-left josefin-light">
                   RD${{ Intl.NumberFormat().format(item.base_grand_total)+'.00' }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-col>
         <v-col
           cols="12"
           class="mt-3 mx-n3 d-lg-none"
           v-for="(item, index) in getOrders.data"
           :key="index"
         >
            <v-row class="my-n10">
              <v-col
                ><p class="josefin-light font-weight-bold ">{{ $t("order.orderNo") }}</p></v-col
              >
              <v-col>
                 <router-link class="gold" :to="'orders/' + item.id">
                      {{ item.id }}
                    </router-link>
              </v-col>
            </v-row>
            <v-row class="my-n10">
              <v-col
                ><p class="josefin-light font-weight-bold ">{{ $t("order.createdAt") }}</p></v-col
              >
              <v-col>
                <p class="josefin-light">
                  {{ moment(item.created_at).format('YYYY-MM-DD') }}</p></v-col
              >
            </v-row>
            <v-row class="my-n10">
              <v-col
                ><p class="josefin-light font-weight-bold ">
                  {{ $t("order.shippingAddress") }}
                </p></v-col
              >
              <v-col>
                <p class="josefin-light ">
                  {{
                    item.shipping_address.address1[0] +
                      ", " +
                      item.shipping_address.city +
                      ", " +
                      item.shipping_address.country_name
                  }}
                </p></v-col
              >
            </v-row>
            <v-row class="my-n10">
              <v-col
                ><p class="josefin-light font-weight-bold ">{{ $t("order.total") }}</p></v-col
              >
              <v-col>
                <p class="josefin-light ">
                  RD${{ Intl.NumberFormat().format(item.base_grand_total)+'.00' }}
                </p></v-col
              >
            </v-row>
            <hr class="mt-8">
          </v-col>
        <v-col cols="1" class="d-none d-lg-flex">
          <v-img src="@/assets/Linea.png" contain class="line-account"></v-img>
        </v-col>
        <v-col cols="12" lg="3" class="pt-10 pb-16 pb-lg-0">
          <v-row>
            <p class="p josefin-light">{{ getUser.name }}</p>
          </v-row>
          <v-row>
            <p class="copperplate">{{ $t("profile.navigationList") }}</p>
          </v-row>
          <v-row>
            <hr class="hr-sub-menu " />
          </v-row>
          <v-row class="pt-5 hr-gold">
            <router-link to="/shipment-info" class="go-to josefin-light p py-2">
              {{ $t("profile.shippingInformation") }}
              <v-img src="/icon/one-arrow.svg" width="10" class="ml-auto" />
            </router-link>
            <router-link to="/profile" class="go-to josefin-light p py-2">
              {{ $t("profile.title") }}
              <v-img src="/icon/one-arrow.svg" width="10" class="ml-auto" />
            </router-link>
            <router-link to="/productos" class="go-to josefin-light p py-2 mb-5">
              {{ $t("profile.logOut") }}
              <v-img src="/icon/one-arrow.svg" width="10" class="ml-auto" />
            </router-link>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import backToStore from "@/components/home/back-to-store.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ordersHistory",
  components: {
    backToStore
  },
  async created() {
    this.fetchOrders();
  },
  data() {
    return {
      orders: []
    };
  },
  computed: {
    ...mapGetters("customer", ["getUser"]),
    ...mapGetters("orders", ["getOrders"])
  },
  methods: {
    ...mapActions("orders", ["fetchOrders"]),
  },
  watch: {
    getOrders(val) {
      this.orders = val;
    }
  }
};
</script>
<style lang="scss" scoped>
th {
  border-top: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.8) !important;
}
.container--fluid {
  min-height: 90vh !important;
}
</style>
